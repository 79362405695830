export type LocaleKey = 'ro' | 'en' | 'hu';

export type Locale = { id: LocaleKey; title: string };

export const locales: Locale[] = [
  { id: 'ro', title: 'Română' },
  { id: 'en', title: 'English' },
  { id: 'hu', title: 'Magyar' },
];

export const localeKeys = locales.map(({ id }) => id);

export const defaultLocaleKey: LocaleKey = 'ro';

export const LOCALE_COOKIE = 'LOCALE_KEY';
