import classNames from 'classnames';

import './logo.css';

type LogoProps = {
  className?: string;
};

export const Logo = ({ className }: LogoProps) => (
  <div className={classNames('flex items-center gap-4', className)}>
    <div className="relative flex w-10 xl:w-20">{svg}</div>
    <div className="text-3xl tracking-wide md:text-xl xl:text-4xl">
      <strong className="font-extrabold">EcoForest</strong>
      <span className="font-thin text-emerald-500">Life</span>
    </div>
  </div>
);

const svg = (
  <svg
    className="logo block h-20 w-full fill-emerald-500"
    version="1.1"
    id="tree_x5F_icon"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="315.013px"
    height="319px"
    viewBox="0 0 315.013 319"
    enableBackground="new 0 0 315.013 319"
    // xml:space="preserve"
  >
    <g>
      <defs>
        <rect id="SVGID_1_" width="315.013" height="319" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <path
          id="Trunk"
          fill="inherit"
          d="M162.635,311.004
			c-13.954,0-27.912-0.193-41.861,0.097c-5.45,0.113-7.811-1.004-6.883-7.165c3.349-22.238,6.309-44.536,9.331-66.821
			c0.354-2.613,1.134-5.756,0.109-7.874c-7.48-15.477-15.316-30.784-23.176-46.074c-1.824-3.55-5.361-2.161-8.087-1.698
			c-27.674,4.714-36.827-7.842-42.125-37.318c-0.524-2.916-0.313-5.966-0.438-8.952c-0.796-19.069,0.448-20.271,19.026-16.93
			c6.148,1.104,12.37,2.388,18.21,4.533c19.404,7.124,26.207,19.446,21.561,39.691c-1.841,8.022-0.854,13.655,6.546,18.462
			c6.915,4.492,13.002,10.3,19.249,15.763c3.959,3.46,6.383,3.814,8.45-1.896c4.055-11.203,8.462-22.286,13.017-33.296
			c1.515-3.661,1.379-5.97-2.317-8.127c-3.704-2.164-7.082-4.923-10.466-7.594c-16.815-13.28-21.033-26.939-14.349-47.35
			c5.63-17.19,16.82-30.691,29.471-43.125c1.755-1.725,3.518-4.385,6.491-1.314c16.55,17.091,31.857,34.758,33.312,60.288
			c0.822,14.428-7.416,23.446-17.503,31.604c-5.392,4.359-14.521,7.996-15.383,13.078c-1.093,6.425,4.312,14.074,7.267,21.047
			c9.239,21.803,8.893,21.26,27.74,6.145c9.419-7.552,14.009-14.383,10.274-27.188c-3.632-12.455,3.37-23.68,15.545-30.403
			c11.648-6.431,24.507-7.677,37.464-8.224c4.578-0.195,7.643,1.503,7.541,6.859c-0.269,13.962-0.888,27.848-7.095,40.755
			c-6.885,14.324-16.977,20.024-32.214,16.437c-7.498-1.764-10.533,0.604-13.697,6.805c-19.977,39.162-20.024,39.095-11.353,82.593
			c2.007,10.078,3.31,20.292,5.177,30.398c0.95,5.127-0.424,7.045-5.979,6.908C191.213,310.77,176.922,311.002,162.635,311.004z"
        />
        <path
          id="First_x5F_4"
          fill="inherit"
          d="M47.94,234.468
			c-0.234-7.348,1.064-14.458,3.603-21.317c6.19-16.726,23.1-23.248,38.972-15.298c3.887,1.947,2.999,5.557,3.722,8.572
			c5.468,22.792-2.396,34.771-25.357,38.762c-2.276,0.397-4.603,0.5-6.883,0.884C48.216,248.388,47.917,248.151,47.94,234.468z"
        />
        <path
          id="First_x5F_1"
          fill="inherit"
          d="M270.733,234.024
			c0.004,13.035-1.402,14.093-13.193,12.338c-9.184-1.366-18.212-2.936-25.977-8.535c-9.872-7.121-13.146-30.049-5.507-38.73
			c6.414-7.288,30.464-3.522,36.834,5.749C269.164,213.981,269.689,224.595,270.733,234.024z"
        />
        <path
          id="First_x5F_2"
          fill="inherit"
          d="M251.485,74.987
			c0.704,21.99-10.197,26.148-29.348,29.548c-1.154,0.205-2.681-0.146-3.67-0.794c-10.674-6.99-14.784-27.426-7.672-38.396
			c6.603-10.179,16.718-15.632,27.479-20.34c4.122-1.803,5.399,0.356,6.585,3.271C248.482,57.172,251.809,66.156,251.485,74.987z"
        />
        <path
          id="First_x5F_3"
          fill="inherit"
          d="M117.667,89.207
			c-0.345,5.914-6.777,12.926-11.848,20.577c-0.456,0.687-1.591,1.16-2.477,1.294c-16.479,2.479-32.913-13.825-30.981-30.708
			c0.151-1.312,0.45-2.626,0.836-3.89c2.797-9.141,0.804-23.853,11.819-25.033C99.25,49.922,117.949,72.12,117.667,89.207z"
        />
        <path
          id="second_x5F_6"
          fill="inherit"
          d="M37.381,71.633
			c14.928-0.004,24.103,11.03,20.918,24.164c-1.598,6.589-17.117,11.62-23.88,7.384c-8.681-5.437-12.183-14.354-14.857-23.707
			c-1.087-3.807,1.787-4.623,4.137-5.354C28.395,72.659,33.127,71.039,37.381,71.633z"
        />
        <path
          id="second_x5F_5"
          fill="inherit"
          d="M259.244,98.359
			c0.823-6.983,1.399-14.852,9.626-18.629c9.483-4.354,18.662-1.783,27.716,1.832c1.967,0.785,2.2,2.037,1.577,3.873
			c-3.197,9.435-6.191,19.068-15.806,24.211C274.23,113.992,259.33,106.989,259.244,98.359z"
        />
        <path
          id="second_x5F_4"
          fill="inherit"
          d="M211.649,33.753
			c-0.023,11.904-8.096,19.936-19.961,19.874c-5.483-0.029-13.84-14.819-11.7-21.556c3.517-11.077,12.48-16.521,22.561-20.648
			c3.329-1.363,3.997,0.923,4.855,3.098C209.846,20.703,211.828,26.992,211.649,33.753z"
        />
        <path
          id="second_x5F_3"
          fill="inherit"
          d="M26.936,165.499
			c17.453,0.568,21.479,10.679,20.894,23.798c-0.317,7.094-16.936,12.414-23.993,7.954c-8.724-5.513-11.684-14.792-14.906-23.842
			c-0.964-2.706,0.816-3.45,2.765-4.158C16.98,167.329,22.348,165.777,26.936,165.499z"
        />
        <path
          id="second_x5F_2"
          fill="inherit"
          d="M289.974,165.515
			c5.487,0.929,14.459,0.251,17.57,6.062c2.48,4.628-2.909,11.513-6.138,16.842c-4.361,7.202-10.013,13.294-19.201,10.29
			c-6.736-2.202-15.031-4.022-13.135-15.062C271.109,171.782,277.013,165.711,289.974,165.515z"
        />
        <path
          id="second_x5F_1"
          fill="inherit"
          d="M109.297,32.486
			c0.902-7.784,0.817-18.596,7.163-20.65c6.543-2.116,14.26,5.082,19.62,11.162c7.557,8.573,5.801,24.367-3.244,30
			c-5.783,3.602-21.126-4.846-22.896-12.769C109.368,37.667,109.483,34.951,109.297,32.486z"
        />
      </g>
    </g>
  </svg>
);
