import { useState, useEffect } from 'react';

type UseIsScrolledToTop = {
  isScrolledToTop: boolean;
};

export const useIsScrolledToTop = (): UseIsScrolledToTop => {
  const [isScrolledToTop, setIsScrolledToTop] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolledToTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isScrolledToTop };
};
