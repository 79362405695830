'use client';

/* eslint-disable @next/next/no-img-element */
// import { useEffect, useState } from 'react';

import classNames from 'classnames';

import { DarkModeIcon } from './DarkModeIcon';

import { LocaleKey } from '@/constants/locales';
import { LanguageSelector } from './LanguageSelector';

type SecondaryNavigationProps = {
  localeKey: LocaleKey;
  hasDarkThemeCookie: boolean;
  className?: string;
};

export const SecondaryNavigation = ({ hasDarkThemeCookie, className, localeKey }: SecondaryNavigationProps) => (
  <div className={classNames('-mr-4 flex items-center justify-end gap-2', className)}>
    <DarkModeIcon hasDarkThemeCookie={hasDarkThemeCookie} />
    <LanguageSelector localeKey={localeKey} />
  </div>
);
