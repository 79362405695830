'use client';

import { useState } from 'react';

import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import { SecondaryNavigation } from './SecondaryNavigation/SecondaryNavigation';
import { Logo } from './Logo';

import { Navigation as SanityNavigation } from '@/types/sanity.types';
import { useIsScrolledToTop } from '@/hooks/useIsScrolledToTop';
import { LocaleKey } from '@/constants/locales';
import { getLocaleSpecificUrl } from '@/utils/locale';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';

type NavigationProps = {
  navigation: SanityNavigation[];
  localeKey: LocaleKey;
  hasDarkThemeCookie: boolean;
};

const getNavigation = (
  localeKey: LocaleKey,
  nav: SanityNavigation[],
  currentPathName: string | null,
): SanityNavigation[] =>
  nav.map((item) => ({ ...item, current: getLocaleSpecificUrl(localeKey, item.page_url ?? '') === currentPathName }));

export const Navigation = (props: NavigationProps) => {
  const { localeKey, hasDarkThemeCookie } = props;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const pathname = usePathname();
  const navigation = getNavigation(localeKey, props.navigation, pathname);
  const isHome = getLocaleSpecificUrl(localeKey, '/') === pathname;
  const { isScrolledToTop } = useIsScrolledToTop();

  return (
    <header
      className={classNames('fixed inset-x-0 top-0 z-[100] select-none transition-all duration-300', {
        'bg-gray-100/80 shadow-xl backdrop-blur-lg dark:bg-gray-900/80': !isHome || !isScrolledToTop,
        'bg-gradient-to-b from-white to-transparent dark:from-black/50': isHome && isScrolledToTop,
      })}
      suppressHydrationWarning
    >
      <MaxWidthContainer className="container mx-auto flex items-center justify-between py-2 sm:py-6">
        <a
          href={getLocaleSpecificUrl(localeKey, '/')}
          title="Home"
          className="relative inline-flex flex-col text-black/70 dark:text-white"
          suppressHydrationWarning
        >
          <Logo />
        </a>

        <div className="hidden text-emerald-600 dark:text-white lg:flex xl:gap-x-2">
          {navigation.map((item) => (
            <a
              key={item.page_title}
              href={getLocaleSpecificUrl(localeKey, item.page_url ?? '')}
              className="rounded-lg px-3 py-2 text-base leading-6 tracking-wide hover:bg-primary-500/5 dark:hover:bg-white/5 xl:font-semibold"
            >
              {item.page_title}
            </a>
          ))}
        </div>
        <SecondaryNavigation
          className="ml-auto mr-4 hidden lg:ml-0 lg:mr-0 lg:flex"
          hasDarkThemeCookie={hasDarkThemeCookie}
          localeKey={localeKey}
        />

        {!mobileMenuOpen && (
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
              onClick={() => setMobileMenuOpen(true)}
              title="Navigation"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-8 w-8" aria-hidden="true" />
            </button>
          </div>
        )}
      </MaxWidthContainer>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-[999] w-full overflow-y-auto bg-gray-600 px-6 py-6 text-gray-200 transition-colors dark:bg-background-900 dark:text-gray-400 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          {mobileMenuOpen && (
            <>
              <button
                type="button"
                className="absolute right-1.5 rounded-md p-2.5"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-8 w-8" aria-hidden="true" />
              </button>
              <div className="mt-10 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.page_title}
                        href={getLocaleSpecificUrl(localeKey, item.page_url ?? '')}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 tracking-wide hover:bg-gray-800"
                      >
                        {item.page_title}
                      </a>
                    ))}
                  </div>

                  <SecondaryNavigation className="py-6" hasDarkThemeCookie={hasDarkThemeCookie} localeKey={localeKey} />
                </div>
              </div>
            </>
          )}
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
