import classNames from 'classnames';
import { RO, GB, HU, UA } from 'country-flag-icons/react/3x2';
import { useState } from 'react';
import { FocusOn } from 'react-focus-on';
import { usePathname } from 'next/navigation';

import { locales, Locale, LocaleKey, LOCALE_COOKIE } from '@/constants/locales';
import { getLocaleSpecificUrl } from '@/utils/locale';

const Caret = (
  <svg
    className="ml-2.5 h-2.5 w-2.5 opacity-50 transition-opacity group-hover:opacity-100"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 10 6"
  >
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
  </svg>
);

const Flag = ({ id, ...props }: { [index: string]: string }) => {
  const Icon = id === 'ro' ? RO : id === 'en' ? GB : id === 'hu' ? HU : id === 'ua' ? UA : undefined;
  if (!Icon) {
    throw Error(`No icon was hardcoded for the flag id "${id}. Need to update this component"`);
  }
  return <Icon {...props} />;
};

type LanguageSelectorProps = {
  localeKey: LocaleKey;
  alignDropDown?: 'right' | 'left';
  className?: string;
};

export const LanguageSelector = ({ localeKey, alignDropDown, className }: LanguageSelectorProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const [selected, setSelected] = useState<Locale>(locales.find(({ id }) => id === localeKey) || locales[0]);
  const pathname = usePathname();

  const selectLocaleHandler = (locale: Locale) => {
    setIsOpened(false);
    setSelected(locale);
    document.cookie = `${LOCALE_COOKIE}=${locale.id}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT`;
    if (locale !== selected) {
      window.location.reload();
    }
  };

  return (
    <div className={classNames('group relative inline-flex flex-col', className)}>
      <button
        id="language-selector"
        data-dropdown-toggle="dropdown-languages"
        className="flex w-[100px] flex-shrink-0 select-none items-center justify-center rounded-lg px-3 py-2 text-sm font-semibold uppercase leading-6 transition-colors hover:bg-white/10"
        type="button"
        onClick={() => setIsOpened((opened) => !opened)}
        aria-expanded={isOpened}
        aria-controls="dropdown-languages"
        aria-label={selected.title}
        lang={selected.id}
      >
        <Flag id={selected.id} className="mr-2 h-3" aria-hidden="true" />
        {selected.id}
        {Caret}
      </button>
      <FocusOn enabled={isOpened} onEscapeKey={() => setIsOpened(false)} onClickOutside={() => setIsOpened(false)}>
        <div
          id="dropdown-languages"
          className={classNames(
            'absolute z-10 w-44 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-800',
            { 'right-0': alignDropDown !== 'left' },
          )}
        >
          <ul
            className={classNames('py-2 text-sm text-gray-700 dark:text-gray-200', { hidden: !isOpened })}
            role="listbox"
          >
            {locales.map((locale) => (
              <li key={locale.id}>
                <a
                  type="button"
                  className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                  href={getLocaleSpecificUrl(locale.id, pathname)}
                  onClick={() => selectLocaleHandler(locale)}
                  lang={locale.id}
                  aria-selected={locale.id === selected.id}
                  role="option"
                >
                  <div className="inline-flex items-center">
                    <Flag id={locale.id} className="mr-2 h-3.5 w-3.5 " aria-hidden="true" />
                    {locale.title}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </FocusOn>
    </div>
  );
};
