import { twMerge } from 'tailwind-merge';

type MaxWidthContainerProps = {
  children: React.ReactNode;
  className?: string;
};

export const MaxWidthContainer = ({ children, className }: MaxWidthContainerProps) => (
  <div className={twMerge('container mx-auto max-w-7xl px-3 sm:px-4 md:px-6 lg:px-8', className)}>{children}</div>
);
