import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/app/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/vercel/path0/components/Navigation/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HighlightInit"] */ "/vercel/path0/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"preload\":true}],\"variableName\":\"inter\"}");
